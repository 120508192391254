import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPlay, updatePlay, deletePlay } from '../redux/slices/playsSlice';
import { fetchArtists } from '../redux/slices/artistsSlice';
import { toast } from 'react-toastify';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FileDropzone from './FileDropzone';
import WarningDialog from './WarningDialog';

// This is in bytes
const MAX_IMAGE_SIZE = 1000000;
const ACCEPTED_FORMATS = { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] };

const empty = (value) => !validator.isEmpty(value, { ignore_whitespace: true });

function PlaysDrawer({ open, onClose, play }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.plays.isLoading);

  const artists = useSelector((state) => state.artists.data);
  const artistsOptions = useMemo(
    () =>
      artists.map(({ id, first_name, last_name, profile_image_url }) => ({
        id,
        label: `${first_name} ${last_name}`,
        profile_image_url,
      })),
    [artists]
  );

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [selectedPortraitUrl, setSelectedPortraitUrl] = useState('');
  const [selectedCoverUrl, setSelectedCoverUrl] = useState('');
  const [selectedPortrait, setSelectedPortrait] = useState(null);
  const [selectedCover, setSelectedCover] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [index, setIndex] = useState(true);
  const [warningOpen, setWarningOpen] = useState(false);
  const [director, setDirector] = useState('');
  const [scenography, setScenography] = useState('');

  const createDisabled = useMemo(
    () =>
      isLoading ||
      !empty(title) ||
      !selectedCover ||
      !selectedPortrait ||
      !description ||
      description.length > 1536 ||
      !selectedArtists.length,
    [isLoading, title, description, selectedCover, selectedPortrait, selectedArtists]
  );
  const updateDisabled = useMemo(
    () => isLoading || !empty(title) || !description || description.length > 1536 || !selectedArtists.length,
    [isLoading, title, description, selectedArtists]
  );

  const onPortrait = useCallback(
    (file) => {
      setSelectedPortrait(file);
    },
    [setSelectedPortrait]
  );

  const onCover = useCallback(
    (file) => {
      setSelectedCover(file);
    },
    [setSelectedCover]
  );

  const onDrawerClose = () => {
    onClose();
    setTitle('');
    setStatus('');
    setDescription('');
    setSelectedCoverUrl('');
    setSelectedPortraitUrl('');
    setSelectedCover(null);
    setSelectedPortrait(null);
    setSelectedArtist(null);
    setSelectedArtists([]);
    setIndex(true);
    setDirector('');
    setScenography('');
  };

  useEffect(() => {
    dispatch(fetchArtists());
    if (play) {
      const {
        name,
        status,
        description,
        director,
        scenography,
        cover_image_url,
        portrait_image_url,
        cast,
        index_for_search,
      } = play;
      setStatus(status);
      setTitle(name);
      setDescription(description);
      setSelectedCoverUrl(cover_image_url);
      setSelectedPortraitUrl(portrait_image_url);
      setSelectedArtists(
        cast.map(({ first_name, last_name, ...rest }) => ({
          label: `${first_name} ${last_name}`,
          ...rest,
        }))
      );
      setIndex(Boolean(index_for_search));
      setDirector(director || '');
      setScenography(scenography || '');
    }
  }, [play, dispatch]);

  const onCreatePlay = () => {
    const payload = {
      name: title.trim(),
      description: description.trim(),
      portrait: selectedPortrait,
      cover: selectedCover,
      cast: selectedArtists.map(({ id }) => id),
      index_for_search: index,
      scenography: scenography.trim(),
      director: director.trim(),
    };
    dispatch(createPlay(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Play created successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const onUpdatePlay = () => {
    const payload = {
      playId: play.id,
      name: title.trim(),
      status,
      description: description.trim(),
      portrait: selectedPortrait,
      cover: selectedCover,
      cast: selectedArtists.map(({ id }) => id),
      index_for_search: index,
      scenography: scenography.trim(),
      director: director.trim(),
    };
    dispatch(updatePlay(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Play updated successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        onDrawerClose();
      }
    });
  };

  const addArtist = () => {
    setSelectedArtists((current) => [...current, selectedArtist]);
    setSelectedArtist(null);
  };

  const removeArtist = (idToRemove) => {
    const idx = selectedArtists.findIndex(({ id }) => idToRemove === id);
    const newState = [...selectedArtists];
    newState.splice(idx, 1);
    setSelectedArtists(newState);
  };

  const getOptionDisabled = (option) => {
    const { id } = option;
    return !!selectedArtists.find((artist) => artist.id === id);
  };

  const toggleOpenWarning = () => {
    setWarningOpen(!warningOpen);
  };

  const onDeletePlay = () => {
    const payload = {
      playId: play.id,
    };
    dispatch(deletePlay(payload)).then(({ payload }) => {
      if (payload) {
        toast.success('Play deleted successfuly', { position: toast.POSITION.BOTTOM_RIGHT });
        toggleOpenWarning();
        onDrawerClose();
      }
    });
  };

  return (
    <Drawer anchor="right" open={open}>
      <Toolbar />
      <Toolbar>
        <Typography variant="button">{!play ? 'Create play' : 'Edit play'}</Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ width: 400, pl: 2, pr: 2 }}>
        <Box component="form">
          <TextField
            required
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            name="title"
            label="Title"
          />
          {play && (
            <TextField
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              variant="standard"
              margin="normal"
              size="small"
              fullWidth
              select
              label="Status"
            >
              <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
              <MenuItem value={'ARCHIVED'}>ARCHIVED</MenuItem>
            </TextField>
          )}
          <TextField
            multiline
            minRows={5}
            maxRows={10}
            required
            value={description}
            onChange={(event) => setDescription(event.target.value.replace('\n', '\r\n'))}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            name="description"
            label="Description"
            helperText="Max length is 1536 characters"
          />
          <TextField
            value={director}
            onChange={(event) => setDirector(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            name="director"
            label="Director"
          />
          <TextField
            value={scenography}
            onChange={(event) => setScenography(event.target.value)}
            fullWidth
            variant="standard"
            margin="normal"
            size="small"
            name="scenography"
            label="Scenography"
          />
          <Box mt={2}>
            <Typography sx={{ display: 'inline-block', marginBottom: 2 }} variant="button">
              Cast *
            </Typography>
            <Autocomplete
              autoComplete
              getOptionDisabled={getOptionDisabled}
              value={selectedArtist}
              onChange={(event, newValue) => {
                setSelectedArtist(newValue);
              }}
              disablePortal
              options={artistsOptions}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} variant="standard" name="artists" label="Search artist" placeholder="Name..." />
              )}
            />
            <Button
              sx={{ marginTop: 2 }}
              onClick={addArtist}
              disabled={!selectedArtist}
              fullWidth
              variant="text"
              size="small"
            >
              Add
            </Button>
            <List>
              {selectedArtists.map(({ id, label, profile_image_url }) => (
                <ListItem key={id} divider>
                  <ListItemAvatar>
                    <Avatar src={profile_image_url} />
                  </ListItemAvatar>
                  <ListItemText primary={label} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => removeArtist(id)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box mt={2}>
            <Typography sx={{ display: 'inline-block', marginBottom: 2 }} variant="button">
              Portrait Image *
            </Typography>
            <FileDropzone
              sx={{ height: 300 }}
              previewUrl={selectedPortraitUrl}
              preview
              fileTypes={ACCEPTED_FORMATS}
              maxSize={MAX_IMAGE_SIZE}
              onDrop={onPortrait}
            />
          </Box>
          <Box mt={2} mb={2}>
            <Typography sx={{ display: 'inline-block', marginBottom: 2 }} variant="button">
              Cover Image *
            </Typography>
            <FileDropzone
              sx={{ height: 300 }}
              previewUrl={selectedCoverUrl}
              preview
              fileTypes={ACCEPTED_FORMATS}
              maxSize={MAX_IMAGE_SIZE}
              onDrop={onCover}
            />
          </Box>
          <Typography variant="body2">Max size: 1MB</Typography>
          <Typography sx={{ mb: 2 }} variant="body2">
            File format: JPEG,JPG,PNG
          </Typography>
          <FormGroup sx={{ display: 'block', mt: 2, mb: 2 }}>
            <FormControlLabel
              control={<Switch checked={index} onChange={(event) => setIndex(event.target.checked)} />}
              label="Index in main site"
            />
          </FormGroup>
        </Box>
        {play && (
          <>
            <Button disabled={!play.can_delete} onClick={toggleOpenWarning} fullWidth variant="outlined">
              Delete
            </Button>
            <WarningDialog
              open={warningOpen}
              loading={isLoading}
              onCancel={toggleOpenWarning}
              onConfirm={onDeletePlay}
              title="Warning"
              content="Are you sure you want to delete this play?"
            />
          </>
        )}
      </Box>
      <Box sx={{ p: 2, mt: 'auto' }}>
        <Grid columnSpacing={2} container>
          <Grid xs={6} item>
            <Button fullWidth variant="outlined" onClick={onDrawerClose}>
              Cancel
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              disabled={!play ? createDisabled : updateDisabled}
              fullWidth
              variant="contained"
              onClick={!play ? onCreatePlay : onUpdatePlay}
            >
              {!play ? 'Create' : 'Update'}
              {isLoading && <CircularProgress size={24} sx={{ ml: 1, color: 'white' }} />}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

export default PlaysDrawer;
